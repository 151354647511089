<template>
  <section class="product">
    <div class="banner">
      <div class="bannerContent_">
        <div class="bannerTitle animate__animated animate__fadeInUp">有智慧的系统</div>
        <div class="bannerContent animate__animated animate__fadeInUp animate__delay-1s">
          全贸通供应链管理系统以数智化方式，构筑全面、精细化的企业管理体系，20年国际贸易进出口经验全面数字化，集成化，赋能企业管理，让工作更轻松、企业更高效。
        </div>
      </div>
    </div>
    <div class="productInfo">
      <div>产品介绍</div>
    </div>
    <div class="productExplain">订货</div>
    <div class="productExplain mb40">为合作客户、下属门店提供快速订货工具</div>
    <div class="productList mb60">
      <div class="productItem" v-for="(item, index) in productList1" :key="index">
        <img :src="item.image" alt="" />
        <div class="productItemTitle">{{ item.title }}</div>
        <div class="productItemExplain">{{ item.explain }}</div>
      </div>
    </div>
    <div class="productExplain">CDM基础数据管理系统</div>
    <div class="productExplain mb40">提供集中式基础资料管理服务</div>
    <div class="productList mb90">
      <div class="productItem" v-for="(item, index) in productList2" :key="index">
        <img :src="item.image" alt="" />
        <div class="productItemTitle">{{ item.title }}</div>
        <div class="productItemExplain">{{ item.explain }}</div>
      </div>
    </div>
    <div class="productInfo mb90">
      <div>解决问题</div>
    </div>
    <div class="productProblem mb90" ref="productProblem" style="justify-content: center; background: white">
      <div style="display: flex; width: 1700px; background: #0f3363">
        <img v-show="productProblemShow" class="animate__animated animate__fadeInRight productProblemBanner" src="@/assets/img/product/banner1.jpg" alt="" />
        <div v-show="productProblemShow" class="productProblemRight animate__animated animate__fadeInRight animate__delay-1s">
          <div class="productProblemFloor">
            <div class="productProblemItem" v-for="(item, index) in problemList1" :key="index">
              <img :src="item.image" alt="" />
              <div class="productProblemItemTitle">{{ item.title }}</div>
            </div>
          </div>
          <div class="productProblemFloor">
            <div class="productProblemItem" v-for="(item, index) in problemList2" :key="index">
              <img :src="item.image" alt="" />
              <div class="productProblemItemTitle">{{ item.title }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="productInfo mb90">
      <div>产品历程</div>
    </div>
    <div class="productSchedule mb90" ref="productSchedule">
      <div class="productScheduleList">
        <div
          class="productScheduleItem animate__animated animate__fadeIn"
          :class="[item.index === acIndex && 'productScheduleItemAc']"
          v-for="(item, index) in productScheduleList1"
          :key="index"
          v-show="acIndex >= item.index"
        >
          <div class="productScheduleItemTime">{{ item.time }}</div>
          <div>{{ item.title }}</div>
        </div>
      </div>
      <div class="line" v-show="productScheduleShow">
        <div class="lineItem animate__animated animate__fadeIn" :class="[`animate__delay-${item - 1}s`]" v-for="item in 6" :key="item"></div>
      </div>
      <div class="productScheduleList">
        <div
          class="productScheduleItem animate__animated animate__fadeIn"
          :class="[item.index === acIndex && 'productScheduleItemAc']"
          v-for="(item, index) in productScheduleList2"
          :key="index"
          v-show="acIndex >= item.index"
        >
          <div class="productScheduleItemTime">{{ item.time }}</div>
          <div>{{ item.title }}</div>
        </div>
      </div>
    </div>
    <div class="productInfo mb90">
      <div>服务客户</div>
    </div>
    <div class="clientList mb90">
      <img src="@/assets/img/product/client1.jpg" alt="" />
      <img src="@/assets/img/product/client2.jpg" alt="" />
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      productList1: [
        {
          image: require("@/assets/img/product/icon1.png"),
          title: "一键订货",
          explain: "便携的快速订货系统",
        },
        {
          image: require("@/assets/img/product/icon2.png"),
          title: "销售式客户管理",
          explain: "以销售为单位的多客户订货管理",
        },
        {
          image: require("@/assets/img/product/icon3.png"),
          title: "订单加密",
          explain: "支持设备多客户使用情况下对下单客户进行加密",
        },
      ],
      productList2: [
        {
          image: require("@/assets/img/product/icon4.png"),
          title: "竞品管理",
          explain: "提供市场竞品管理基础资料库",
        },
        {
          image: require("@/assets/img/product/icon5.png"),
          title: "多种基础资料管理",
          explain: "覆盖商品、供应商、销售、服务商、仓库、库位 等基础资料",
        },
        {
          image: require("@/assets/img/product/icon6.png"),
          title: "多业务分类",
          explain: "按基础维护、采购、销售、仓库管理等部门分别 设置入口",
        },
      ],
      problemList1: [
        {
          image: require("@/assets/img/product/p1.png"),
          title: "产品断货，供应链岌岌可危",
        },
        {
          image: require("@/assets/img/product/p2.png"),
          title: "无法实时监管企业运转情况",
        },
      ],
      problemList2: [
        {
          image: require("@/assets/img/product/p3.png"),
          title: "运营数据时效差导致企业决策 失去先机",
        },
        {
          image: require("@/assets/img/product/p4.png"),
          title: "库存精度低，时常出现货物丢失",
        },
      ],
      productScheduleList1: [
        {
          time: "2020.5",
          title: "项目立项",
          index: 0,
        },
        {
          time: "2020.10",
          title: "ERP上线",
          index: 2,
        },
        {
          time: "2020.10",
          title: "PDA上线",
          index: 4,
        },
      ],
      productScheduleList2: [
        {
          time: "2020.7",
          title: "研发",
          index: 1,
        },
        {
          time: "2021.6",
          title: "ERP交付使用",
          index: 3,
        },
        {
          time: "2021.6",
          title: "PDA交付使用",
          index: 5,
        },
      ],
      productProblemShow: false,
      acIndex: 0,
      timer: null,
      productScheduleShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // 实现当滚动到指定位置，触发动画
      // let scrollTop =  window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop // 获取窗口滚动条高度
      // this.gdjz('earth', 'earth animated bounceInDown', 50)
      let _this = this;
      let refArray = [
        { ref: "productProblem", show: "productProblemShow" },
        { ref: "productSchedule", show: "productScheduleShow" },
      ];
      refArray.forEach((r) => {
        _this.gdjz(r.ref, 20, () => {
          if (r.ref === "productSchedule" && !this.timer) {
            this.timer = setInterval(() => {
              this.acIndex++;
              if (this.acIndex === 5) {
                clearInterval(this.timer);
              }
            }, 1000);
          } else {
            _this[r.show] = true;
          }
        });
      });
    },
    gdjz(div, offset, callback) {
      let dom = this.$refs[div]; // 等同于document.querySelector('.earth') true
      if (dom) {
        let a, b, c, d;
        d = dom.offsetTop; // 元素距离相对父级的高度，这里父级指的是body
        a = eval(d + offset);
        b = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop; //  获取窗口滚动条高度
        c = document.documentElement.clientHeight || document.body.clientHeight; // 获取浏览器可视区的高度
        if (b + c > a) {
          callback && callback();
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  .banner {
    margin-bottom: 90px;
    width: 100%;
    height: 536px;
    background-image: url("../assets/img/product/banner.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 120px;
    .bannerTitle {
      font-size: 60px;
      color: white;
      margin-bottom: 60px;
    }
    .bannerContent {
      font-size: 24px;
      line-height: 50px;
      color: white;
      width: 750px;
    }
  }
  .productInfo {
    height: 2px;
    font-size: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #cccccc;
    width: 435px;
    margin-bottom: 40px;
    div {
      background: white;
      padding: 0 20px;
    }
  }
  .productExplain {
    font-size: 20px;
    line-height: 32px;
    color: #333333;
  }
  .mb40 {
    margin-bottom: 40px;
  }
  .mb60 {
    margin-bottom: 60px;
  }
  .mb90 {
    margin-bottom: 90px;
  }
  .productList {
    width: 1436px;
    display: flex;
    justify-content: space-between;
    .productItem {
      width: 392px;
      height: 223px;
      background: #f6f6f6;
      border-radius: 19px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 72px;
        height: 72px;
        margin-bottom: 20px;
      }
      .productItemTitle {
        font-size: 19px;
        color: #333333;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .productItemExplain {
        font-size: 16px;
        color: #666666;
        width: 330px;
        text-align: center;
      }
    }
  }
  .productProblem {
    display: flex;
    height: 552px;
    background: #0f3363;
    width: 100%;
    .productProblemBanner {
      width: 659px;
      height: 552px;
    }
    .productProblemRight {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-left: 150px;
      flex-wrap: wrap;
      flex-grow: 1;
      .productProblemFloor {
        height: 200px;
        display: flex;
        .productProblemItem {
          height: 200px;
          width: 312px;
          margin-right: 222px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-size: 24px;
          line-height: 36px;
          color: white;
          img {
            width: 70px;
            height: 74px;
            object-fit: contain;
            margin-bottom: 24px;
          }
        }
      }
    }
  }
  .productSchedule {
    .productScheduleList {
      display: flex;
      margin-bottom: 24px;
      &:last-child {
        margin-top: 24px;
        padding-left: 275px;
      }
      .productScheduleItem {
        margin-right: 357px;
        width: 197px;
        height: 110px;
        background: #f6f6f6;
        border-radius: 6px;
        display: flex;
        padding-left: 42px;
        justify-content: center;
        flex-direction: column;
        font-size: 20px;
        color: #010101;
        &:last-child {
          margin-right: 0;
        }
        .productScheduleItemTime {
          color: #0f3363;
        }
      }
      .productScheduleItemAc {
        color: white;
        background: #0f3363;
        .productScheduleItemTime {
          color: white;
        }
      }
    }
    .line {
      height: 2px;
      background: #cbcbcb;
      width: 1580px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 90px;
      .lineItem {
        width: 18px;
        height: 18px;
        background: #0f3363;
        border-radius: 50%;
      }
    }
  }
  .clientList {
    display: flex;
    img {
      width: 485px;
      height: 281px;
      &:hover {
        transform: scale(1.1);
        transition: all 1s;
      }
    }
  }
}
</style>
